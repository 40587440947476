import { Col, Form, Input, Row } from 'antd';
import { CustomFormItem } from 'components/B2bUserForm';
import CustomButton from 'components/CustomButton';
import { NotificationManager } from 'components/Notification';
import PunchoutInfoModal from 'components/PunchoutInfoModal';
import { useAsync } from 'hooks';
import { ASYNC_STATUS } from 'libs/constants';
import { PUNCHOUT_TYPES } from 'libs/constants/punchoutTypes';
import { validatePasswordRequirements } from 'libs/utils/passwordValidators';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { adminFapiTechnicalUser } from 'services/adminFapi';

import styles from './TechnicalUserSetPassword.module.scss';

const TechnicalUserSetPassword = (props) => {
  const { userId, username, punchOutType, onCancel } = props;
  const { t } = useTranslation();

  const [shouldDisplayModal, setShouldDisplayModal] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const newPassword = useRef(null);

  const handleSuccess = useCallback(() => {
    if (punchOutType === PUNCHOUT_TYPES.OCI) {
      const base64Secrets = window.btoa(
        `accessKey=${username}&secret=${newPassword.current}`
      );

      const base64Url = `${window.location.origin}/punchout/oci/${base64Secrets[0]}${base64Secrets}`;

      setResponseData({ punchOutType, url: base64Url });
    } else {
      setResponseData({
        punchOutType,
        username,
        password: newPassword.current,
      });
    }

    setShouldDisplayModal(true);
    NotificationManager.success({ message: 'notification.success.update' });
  }, [newPassword, punchOutType, username]);

  const handleSetPassword = useCallback(
    ({ password }) => {
      newPassword.current = password;

      return adminFapiTechnicalUser.setTechnicalUserPassword({
        userId,
        username,
        password,
      });
    },
    [userId, username]
  );

  const { execute, status } = useAsync(handleSetPassword, false, handleSuccess);
  const isUpdating = status === ASYNC_STATUS.PENDING;
  const isError = status === ASYNC_STATUS.ERROR;

  return (
    <>
      {isError && (
        <div className={styles.errorMessage}>
          {t('setPassword.validationMessage.errorMessageWithCode', {
            errorCode: '001',
          })}
        </div>
      )}
      <Form layout="vertical" className="mt-4" onFinish={execute}>
        <Row>
          <Col span={12}>
            <CustomFormItem
              name="password"
              rules={[
                { required: true },
                {
                  validator: validatePasswordRequirements,
                },
              ]}
            >
              <Input.Password autoComplete="off" />
            </CustomFormItem>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col>
            <Form.Item noStyle>
              <CustomButton
                type="ghost"
                disabled={isUpdating}
                onClick={onCancel}
              >
                {t('buttonTexts.cancel')}
              </CustomButton>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item noStyle>
              <CustomButton htmlType="submit" disabled={isUpdating}>
                {t('buttonTexts.saveChanges')}
              </CustomButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <PunchoutInfoModal
        isVisible={shouldDisplayModal}
        content={responseData}
        onOk={() => {
          setShouldDisplayModal(false);
          newPassword.current = '';
          onCancel();
        }}
      />
    </>
  );
};

export default React.memo(TechnicalUserSetPassword);
